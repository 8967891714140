interface provider {
  "@type": string
  name: string
  url: string
}

interface geoMidpoint {
  "@type": string
  latitude: number
  longitude: number
}

interface areaServed {
  "@type": string
  geoMidpoint?: geoMidpoint
  geoRadius?: string
  name?: string
}

interface hasMap {
  "@type": "Map"
  url: string
  mapType: string
  description: string
  name: string
}

interface image {
  "@type": "ImageObject"
  url: string
  name: string
}

interface offer {
  "@type": "Offer"
  itemOffered: {
    "@type": string
    name: string
    serviceType: string
    description: string
    areaServed: areaServed | areaServed[]
  }
}

interface review {
  "@type": "Review"
  author: {
    "@type": string
    name: string
  }
  reviewBody: string
  reviewRating: {
    "@type": string
    ratingValue: number
  }
}

interface aggregateRating {
  "@type": "AggregateRating"
  ratingValue: string
  reviewCount: string
}

interface Question {
  "@type": "Question"
  name: string
  acceptedAnswer: {
    "@type": string
    text: string
  }
}
interface mainEntity {
  "@type": string
  mainEntity: Question[]
}

interface parentOrganization {
  "@type": string
  name: string
  url: string
}

interface geoCoordinates {
  "@type": "GeoCoordinates"
  latitude: string
  longitude: string
}

interface listItem {
  "@type": string
  position: number
  item: {
    "@id": string
    name: string
  }
}

interface place {
  "@type": "Place"
  name: string
}

interface address {
  "@type": "PostalAddress"
  streetAddress: string
  addressLocality: string
  postalCode: string
  addressCountry: string
}

interface person {
  "@type": "Person"
  name: string
}

interface faqPage {
  "@type": string
  mainEntity: Question[]
}

interface structuredData {
  address?: address
  "@context"?: "https://www.schema.org"
  alternateName?: string[]
  foundingDate?: string
  founders?: person[]
  "@type"?: string
  name?: string
  url?: string
  image?: image
  description?: string
  telephone?: string
  provider?: provider
  parentOrganization?: parentOrganization
  areaServed?: areaServed
  hasMap?: hasMap
  offers?: offer[]
  review?: review[]
  aggregateRating?: aggregateRating
  mainEntity?: mainEntity
  makesOffer?: offer[]
  geo?: geoCoordinates
  branchOf?: parentOrganization
}

interface breadCrumbList {
  "@context": "https://schema.org"
  "@type": "BreadcrumbList"
  itemListElement: listItem[]
}

interface dataSet {
  "@context": "https://schema.org"
  "@type": string
  name: string
  description: string
  url: string
  spatialCoverage: place
}

export function buildStructuredData(data: any) {
  const structuredData: structuredData = {
    "@context": "https://www.schema.org",
    name: "AnyVan",
    alternateName: ["Any Van", "AnyVan.com", "Anyvan"],
    url: "https://www.anyvan.com",
    foundingDate: "March 2009",
    founders: [
      {
        "@type": "Person",
        name: "Angus Elphinstone"
      }
    ]
  }

  buildFAQStructuredData(data)

  let location = data.location?.locationIdentifier
  if (location) {
    location = location.charAt(0).toUpperCase() + location.slice(1)
  }

  if (data.frontendUrl && location) {
    injectLdJson(buildBreadcrumbList(location, data.frontendUrl))
    injectLdJson(buildDataset(location, data.frontendUrl))
  }

  if (location === "London" || !location) {
    structuredData["@type"] = !location ? "MovingCompany" : "LocalBusiness"
    structuredData.telephone = "+442030056000"
    structuredData.address = buildAddress()
    structuredData.branchOf = buildOrganization()
    if (location) {
      structuredData.geo = {
        "@type": "GeoCoordinates",
        latitude: "51.49447750066836",
        longitude: "-0.22642663068890526"
      }

      if (data.pageInternalReviews) {
        structuredData.aggregateRating = buildAggregateRating(data.pageInternalReviews)
        structuredData.review = buildReview(data.pageInternalReviews)
      }
    } else {
      injectLdJson(structuredData)
      return
    }
  } else {
    structuredData["@type"] = "Service"
    structuredData.parentOrganization = buildOrganization()
  }
  structuredData.description = `Professional removal services in ${location}`
  structuredData.name = `AnyVan Removals - ${location}`
  structuredData.url = data.frontendUrl
  structuredData.hasMap = buildHasMap(data.frontendUrl, location)
  structuredData.areaServed = buildAreaServed(location)
  structuredData.offers = buildOffers(location)
  injectLdJson(structuredData)
}

export function buildFAQStructuredData(data: any) {
  // Add the FAQ schema if the page contains a FAQ block
  let structuredData: faqPage
  if (data.blocks) {
    const faqBlock = data.blocks.filter(block => {
      for (const attribute of block.attributes) {
        if (attribute.name === "className" && attribute.value.includes("faq")) {
          return true
        }
      }
      return false
    })
    if (faqBlock[0]) {
      const faqs = extractFaqs(faqBlock[0])
      structuredData = {
        "@type": "FAQPage",
        mainEntity: faqs.map(faq => {
          return {
            "@type": "Question",
            name: faq.question,
            acceptedAnswer: {
              "@type": "Answer",
              text: faq.answer
            }
          }
        })
      }
      injectLdJson(structuredData)
    }
  }
}

function injectLdJson(jsonData) {
  const script = document.createElement("script")
  script.type = "application/ld+json"
  script.text = JSON.stringify(jsonData)
  document.head.appendChild(script)
}

function extractFaqs(blockTree) {
  type faq = {
    question: string
    answer: string
  }
  const faqs: faq[] = []
  function traverse(block) {
    if (block.type === "AINOBLOCKS_ACCORDION_FAQ_BLOCK") {
      const question = block.attributes.find(attr => attr.name === "question").value
      const answerBlock = block.innerBlocks.find(
        b => b.type === "CORE_HTML" || b.type === "CORE_PARAGRAPH"
      )
      const answer = answerBlock ? answerBlock.innerHtml : ""

      faqs.push({
        question,
        answer
      })
    }
    if (block.innerBlocks && block.innerBlocks.length > 0) {
      block.innerBlocks.forEach(traverse)
    }
  }
  traverse(blockTree)

  return faqs
}

export function buildBreadcrumbList(location: string, url): breadCrumbList {
  const breadcrumbList: breadCrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "https://www.anyvan.com/",
          name: "Home"
        }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://www.anyvan.com/removals",
          name: "Removals"
        }
      },
      {
        "@type": "ListItem",
        position: 3,
        item: {
          "@id": url,
          name: `${location} Removals`
        }
      }
    ]
  }
  return breadcrumbList
}

export function buildDataset(location, url): dataSet {
  const dataset: dataSet = {
    "@context": "https://schema.org",
    "@type": "Dataset",
    name: `${location} Removals Map`,
    description: `Map showing completed AnyVan removal jobs in the vicinity of ${location}`,
    url: url.replace(/\/$/, "") + "#locationmap",
    spatialCoverage: {
      "@type": "Place",
      name: location
    }
  }
  return dataset
}

export function buildHasMap(url, location): hasMap {
  const map: hasMap = {
    "@type": "Map",
    url: url + "#locationmap",
    mapType: "VectorMap",
    description: `Interactive map showing AnyVan's service area and completed jobs in ${location}`,
    name: `AnyVan ${location} Service Area Map`
  }
  return map
}

export function buildAggregateRating(ratingData): aggregateRating {
  const ratings = ratingData.rating.split(", ").map(Number)
  const average = ratings.reduce((a, b) => a + b, 0) / Number(ratings.length)
  const reviewCount = ratings.length
  const aggregateRating: aggregateRating = {
    "@type": "AggregateRating",
    ratingValue: average.toFixed(1),
    reviewCount
  }
  return aggregateRating
}

export function buildAddress(): address {
  const address: address = {
    "@type": "PostalAddress",
    streetAddress: "5th Floor, The Triangle, 5-17 Hammersmith Grove",
    addressLocality: "London",
    postalCode: "W6 0LG",
    addressCountry: "UK"
  }
  return address
}

export function buildOrganization(): parentOrganization {
  const parent: parentOrganization = {
    "@type": "Organization",
    name: "AnyVan",
    url: "https://www.anyvan.com"
  }
  return parent
}

export function buildAreaServed(location: string): areaServed {
  const area: areaServed = { "@type": "Place", name: location + " and surrounding areas" }
  return area
}

export function buildOffers(location: string): offer[] {
  return [
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Home Removals",
        serviceType: "Moving Services",
        description: `Professional home removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Office Removals",
        serviceType: "Moving Services",
        description: `Professional office removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "International Removals",
        serviceType: "Moving Services",
        description: `Comprehensive international moving services from ${location}`,
        areaServed: [
          {
            "@type": "Country",
            name: "United Kingdom"
          },
          {
            "@type": "Country",
            name: "France"
          },
          {
            "@type": "Country",
            name: "Germany"
          }
        ]
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Student Removals",
        serviceType: "Moving Services",
        description: `Affordable student removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Commercial Removals",
        serviceType: "Moving Services",
        description: `Professional commercial removal services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    },
    {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Storage",
        serviceType: "Storage Services",
        description: `Secure storage services in ${location} and surrounding areas`,
        areaServed: buildAreaServed(location)
      }
    }
  ]
}

export function buildReview(internalReviewData): review[] {
  const dataIsValid = Object.values(internalReviewData).every(value => value !== null)
  let reviews: review[] = []
  if (dataIsValid) {
    const nameList: string[] = internalReviewData.name.split(/,/g).map(str => str.trim())
    const ratingList: number[] = internalReviewData.rating.split(/,/g).map(parseFloat)
    const reviewList: string[] =
      internalReviewData.review.match(/"([^"])*"/g)?.map(str => str.trim()) ?? []

    reviews = reviewList.map((_, index) => {
      return {
        "@type": "Review",
        author: { "@type": "Person", name: nameList[index] },
        reviewRating: { "@type": "Rating", ratingValue: ratingList[index] },
        reviewBody: reviewList[index]
      }
    })
  }
  return reviews
}
