import { Vue, Component } from "nuxt-property-decorator"

/**
 * RegEx explanation
 * [a-zA-Z]+:\/\/ Match any protocol
 * [a-zA-Z0-9-]+ Match the first subdomain, e.g. cms-internal
 * (?:\.[a-zA-Z0-9-]+)* Match the domain, e.g. .anyvan. Also, will match any number of subdomains, e.g. dev.cms.internal
 * \.[a-zA-Z]{2,} Match the top-level domain (TLD), e.g. .com
 */
const matchProtocolAndHost = new RegExp(
  /[a-zA-Z]+:\/\/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}/g
)

@Component
export default class ImageUrlS3 extends Vue {
  replaceUrlS3(url: string) {
    if (!url) {
      return ""
    }

    if (
      this.$config.graphqlEndpoint.includes("cms.anyvan.local") ||
      this.$config.graphqlEndpoint.includes("dev-cms.anyvan.com")
    ) {
      return url
    }
    if (url.includes("/app/uploads")) {
      // WordPress media library images that have been auto-uploaded to S3
      return url
        .replace(matchProtocolAndHost, this.$config.cdnUrl)
        .replace(/\/app\/uploads/g, "/content-uploads")
    }

    if (url.includes("/app/themes/anyvan")) {
      // WordPress theme images
      return url
        .replace(matchProtocolAndHost, this.$config.cdnUrl)
        .replace(/\/app\/themes\/anyvan\/assets\/img/g, "/content-static/wp-theme")
    }

    return url
  }
}
